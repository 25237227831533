.MuiCardHeader-title {
  font-size: medium !important;
  font-weight: 600 !important;
}
.MuiCardHeader-subheader {
  font-size: small !important;
}
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  top: 40% !important;
  right: -5px !important;
}
.css-1qaznj3-MuiCardContent-root:last-child {
  padding-bottom: 0 !important;
}
.Mui-disabled {
  -webkit-text-fill-color: rgb(44 42 42) !important;
}
.MuiSpeedDial-fab {
  height: 40px !important;
  width: 40px !important;
}

.MuiDrawer-root {
  z-index: 900 !important ;
}
.MuiAppBar-root{
  z-index: 1000 !important ;
}
.MuiSpeedDial-root {
  z-index: 99 !important ;
}
.db-list {
  /* padding-left: 0 !important;
  padding-right: 0 !important; */
  list-style: none !important;
}
.db-list > .MuiListItemAvatar-root {
  min-width: 45px !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
