*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f6ff !important;
}
a {
  text-decoration: none;
  color: inherit;
}

.messages::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.messages::-webkit-scrollbar-thumb {
  background: #444ce7;
}
